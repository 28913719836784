import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { initializeAuth, indexedDBLocalPersistence, browserLocalPersistence} from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, getToken, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

//import storage from 'firebase/storage';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyC_Lk5KiPlna7flplN4KJhCVakdlADaVII",
    authDomain: "bwendibase.firebaseapp.com",
    databaseURL: "https://bwendibase-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "bwendibase",
    storageBucket: "bwendibase.appspot.com",
    messagingSenderId: "550667827370",
    appId: "1:550667827370:web:5b4d433d154bc4296c38d3",
    measurementId: "G-QXMMKX5P0E"
  };
  
const app = initializeApp(firebaseConfig, 'bwendibase');
export const db = getFirestore(app);

export const analytics = getAnalytics(app);

export const auth = initializeAuth(app, {
    persistence: [indexedDBLocalPersistence,browserLocalPersistence]
});

//export const storage = getStorage(app);
export const storage = getStorage(app);

export const appCheckToken = getToken;

export const appCheck = initializeAppCheck(
    app,
    { 
    provider: new ReCaptchaEnterpriseProvider('6LeVmI4pAAAAAIWPSybPtWpfOs6FOB0ML1QsesZ-'),
    isTokenAutoRefreshEnabled: true
    } 
);

export const callApiWithAppCheck = async (url, body) => {

    let appCheckTokenResponse;
    try {
        appCheckTokenResponse = await getToken(appCheck, false);
    } catch (err) {
        // Handle any errors if the token was not retrieved.
        return;
    }

    let headers = new Headers();

    headers.append('X-Firebase-AppCheck', appCheckTokenResponse.token);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin','*');

    // Include the App Check token with requests to your server.
    const apiResponse = await fetch(url, {
        method: 'POST',
        mode: "cors",
        credentials: "include",
        headers: headers,
        body: JSON.stringify(body)
    });

    return apiResponse.json();
  
  };